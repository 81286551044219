function gmapInit() {
  // if($("#map-canvas").length === 0){
  //   return false;
  // }

  //Set BAse Lat and Lng but this will be overriden if data params are passed on the #map-canvas
  var lat = 48.469891;
  var lng = -123.375680;
  var address = ($("#map-canvas").data('address') ? $("#map-canvas").data('address') : '');
  var infobox_header = ($("#map-canvas").data('header') ? $("#map-canvas").data('header') : '');
  var infobox_content = ($("#map-canvas").data('content') ? $("#map-canvas").data('content') : '');
  var zoom = 13;

  if($("#map-canvas").data('lat') && $("#map-canvas").data('lng')){
    lat = $("#map-canvas").data('lat');
    lng = $("#map-canvas").data('lng');
  }

  // Set The Main pin content for infobox
  var content = '';
  if(address) {
    content += ($("#map-canvas").data('image') ? '<img src="' + $("#map-canvas").data('image') + '"><br>' : '');
    content += "<h4>"+infobox_header+"</h4>";
    content += "<span class='info-box-content'>"+infobox_content+"</span>";
    content += "<span class='info-box-address'>"+address+"</span>";
    content += '<a target="_blank" href="https://www.google.ca/maps/place/' + address.split(' ').join('+') + '">View Larger</a>';
    content += '</div>';
  }

  //Start off the Google Maps
  var geocoder = new google.maps.Geocoder();
  var myLatlng = new google.maps.LatLng(lat, lng);
  var mapOptions = {
    zoom: zoom,
    center: myLatlng,
    panControl: false,
    zoomControl: true,
    scaleControl: false,
    scrollwheel: false,
    styles:[{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
  };

  if(Modernizr.touch){
    //mapOptions.draggable = false;
  }

  // Build out the Markers for the main
  var $pins, map, markers=[], positions=[], sales_marker;

  map = new google.maps.Map(document.getElementById('map-canvas'), mapOptions);

  var infowindow = new google.maps.InfoWindow({
    content: content,
    size: new google.maps.Size(150, 50)
  });

  // Gather the Pins from the list on the google map page, and build the location array.
  $pins = $( ".map__pin.is-active" ).toArray();

  // Make map  more responsive
  var currCenter = map.getCenter();
  google.maps.event.addDomListener(window, 'resize', function() {
    map.setCenter(currCenter);
  });

  function resetMapCenter() {
    var bounds = new google.maps.LatLngBounds();

    positions.forEach(function(position) {
      bounds.extend(position);
    });

    google.maps.event.addListener(map, 'zoom_changed', function() {
      zoomChangeBoundsListener =
      google.maps.event.addListener(map, 'bounds_changed', function(event) {
        if (this.getZoom() > zoom && this.initialZoom === true) {
          // Change max/min zoom here
          this.setZoom(zoom);
          this.initialZoom = false;
        }
        google.maps.event.removeListener(zoomChangeBoundsListener);
      });
    });

    map.initialZoom = true;
    map.fitBounds(bounds);
    map.setCenter(bounds.getCenter());

    currCenter = map.getCenter();
  }

  function addMarker(latLng, icon, popupContent) {

    var marker = new google.maps.Marker({
      position: latLng,
      map: map,
      icon: icon,
      animation: google.maps.Animation.DROP
    });

    google.maps.event.addListener(marker, 'click', (function(marker) {
      return function() {
        infowindow.setContent(popupContent);
        infowindow.open(map, marker);
      };
    })(marker));

    return marker;
  }

  function addMarkerWithTimeout(pin, timeout) {
    var pinLatLng = new google.maps.LatLng(pin.dataset.lat, pin.dataset.lng);
    positions.push(pinLatLng);

    setTimeout(function() {
     //console.log(pin.dataset.icon);
      var icon;
      if(pin.dataset.icon){
       // console.log("has an image icon");
        icon = pin.dataset.icon;
        icon = {
          url:pin.dataset.icon,
          //21.333333333
          size: new google.maps.Size(32,32),
          scaledSize: new google.maps.Size(21.333333333, 21.333333333),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(10.6665, 10.6665)
        };
      }else{
        icon = {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: pin.dataset.color,
          fillOpacity: 1,
          scale: 9,
          strokeWeight: 2,
          strokeColor: '#ffffff'
        };
      }
      var popupContent = '<div class="gmap-info-box"><h4>'+pin.dataset.title+'</h4><span class="info-box-content">' + pin.dataset.description + '</span><span class="info-box-address">' + pin.dataset.address + "</span></div>";

      var marker = addMarker(pinLatLng, icon, popupContent);
      markers.push(marker);

    }, timeout);
  }

  function addSalesMarker() {
    // Add the sales center marker
    var icon = {
      path: 'M19.4,0C8.7,0,0,8.7,0,19.4c0,14.9,19.4,32.8,19.4,39.2c-0.1-6.3,19.4-24.4,19.4-39.2C38.7,8.7,30,0,19.4,0z M19.4,27.1c-4.1,0-7.3-3.3-7.3-7.3c0-4.1,3.3-7.3,7.3-7.3c4.1,0,7.3,3.3,7.3,7.3C26.7,23.9,23.4,27.1,19.4,27.1z',
      fillColor: '#82c341',
      fillOpacity: 1,
      scale: 1,
      strokeWeight: 0,
      size: new google.maps.Size(11, 22),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(20, 50)
    };
    /**
    * If you need to use an image
    * Must know the size of the image, and then scale that image down for retina use
    */
    // var icon = {
    //   url:'PATH TO PIN',
    //   size: new google.maps.Size(47,80),
    //   scaledSize: new google.maps.Size(31.333333333, 53.333333333),
    //   origin: new google.maps.Point(0, 0),
    //   anchor: new google.maps.Point(15.66665, 26.66665)
    // };
    var pinLatLng = new google.maps.LatLng(lat, lng);
    positions.push(pinLatLng);

    sales_marker = addMarker(pinLatLng, icon, content);
    markers.push(sales_marker);
  }

  function addAllMarkers(pins) {
    var timeout = 0;

    for (var i = 0; i < pins.length; i++) {

      if(!pins[i].dataset.lat || !pins[i].dataset.lng) {
        continue;
      }

      addMarkerWithTimeout(pins[i], timeout);
      timeout += 5;
    }
    //resetMapCenter();
  }

  function clearAllMarkers() {
    for (var i = 0; i < markers.length; i++) {

      if(markers[i] !== sales_marker) {
        markers[i].setMap(null);
      }
    }
    positions = [];
    markers = [];

    // Have that sales marker there always
    positions.push(sales_marker.position);
    markers.push(sales_marker);

  }

  // Sets the map on all markers in the array.
  function setMapOnAll() {
    clearAllMarkers();

    // Grab the active pins
    $pins = $( ".map__pin.is-active" ).toArray();
    addAllMarkers($pins);
    resetMapCenter();
  }

  // Do things
  addSalesMarker();
  addAllMarkers($pins);

  google.maps.event.addDomListener(window, 'orientationchange', function() {
    map.setCenter(currCenter);
  });

  google.maps.event.addListener(map, 'zoom_changed', function() {
    setTimeout(function() {
        var cnt = map.getCenter();
        cnt.e+=0.000001;
        map.panTo(cnt);
        cnt.e-=0.000001;
        map.panTo(cnt);
    },100);
  });

  // Click event for map legend
  $(".map__legend__pin").click(function(e){
    $(".map__legend__pin.is-active").removeClass('is-active');
    $(this).addClass('is-active');

    if($(this).data("cat") === 'all') {
      $('.map__pin').addClass('is-active');
    }
    else {
      $('.map__pin').removeClass('is-active');
      $('.map__pin[data-cat=' + $(this).data("cat") + ']').addClass('is-active');
    }

    setMapOnAll();
  });
}
