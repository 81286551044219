// Hero
function parallaxHero() {
  var scrollVar = $(window).scrollTop();
  var ticking = false;

  // Hero
  var $depth2 = $('.hero.hero--image .wrap, .hero.hero--home .wrap, .hero.hero--project .wrap');
  var $bottom = $('.bottom');

  // // Counter
  // var $counter = $('section.counter');

  // Sliders
  var $sliders = $('section.featured');

  // Two Tile
  var $tiles = $('.two-tile__image');

  // Call Out
  var $galleryItem = $('.gallery__item');

  // Everything plz
  var $all = $('.hero.hero--image .bg-img, .hero.hero--home .bg-img, .hero.hero--project .bg-img, .hero.hero--image .wrap, .hero.hero--home .wrap, .hero.hero--project .wrap');

  function parallax(){
    ticking = false;
    var windowWidth = $(window).width();
    var windowHeight = $(window).height();
    var $postList = $('.posts.post-list');
    var $depth1 = $('.hero.hero--image .bg-img, .hero.hero--home .bg-img, .hero.hero--project .bg-img');

    if(windowWidth >= 768){

      // Hero
      var limitedScrollVar = scrollVar * 0.5;
      var opacity = (1 - scrollVar/($(window).height() * 1.4));
      var opacity2 = (1.05 - scrollVar/($(window).height() * 0.9));

      $depth1.css({ 'transform': 'translate3d(0px, ' + (0.7 * limitedScrollVar) + 'px, 0px)' });
      $depth2.css({ 'transform': 'translate3d(0px, ' + (0.3 * limitedScrollVar) + 'px, 0px)', 'opacity': opacity2 });

      if($bottom.length > 0) {
        $bottom.css({ 'opacity': opacity });
      }

      // Surfaces and Systems
      if($sliders.length > 0) {
        $sliders.css({
          'overflow': 'hidden'
        });
        $sliders.find('.bg-img').css({
          'height': '120%',
          'top': 'auto',
          'bottom': '0'
        });

        $sliders.each(function() {
          that = $(this);
          var thisRatio = 0.2;
          var thisScoll = (scrollVar - (that.offset().top - windowHeight)) * 0.5;
          var thisScrolled = thisScoll * thisRatio;
          var thisOffsetMax = that.height();
          var thisBg = that.find('.bg-img');

          if(scrollVar >= (that.offset().top - windowHeight) && thisScrolled <= thisOffsetMax) {
            thisBg.css({ 'transform': 'translate3d(0px, ' + (thisRatio * thisScoll * 1) + 'px, 0px)' });
          }
          else if(thisScrolled > thisOffsetMax) {
            thisBg.css({ 'transform': 'translate3d(0px, ' + thisOffsetMax + 'px, 0px)' });
          }
          else {
            thisBg.css({ 'transform': 'translate3d(0px, 0px, 0px)' });
          }
        });
      }

      if($tiles.length > 0) {
        $tiles.each(function() {
          that = $(this);
          var thisRatio = 0.2;
          var thisScoll = (scrollVar - (that.offset().top - windowHeight)) * 0.5;
          var thisScrolled = thisScoll * thisRatio;
          var thisOffsetMax = that.height();
          var thisBg = that.find('.bg-img');

          if(scrollVar >= (that.offset().top - windowHeight) && thisScrolled <= thisOffsetMax) {
            thisBg.css({ 'transform': 'translate3d(0px, ' + (thisRatio * thisScoll * -1) + 'px, 0px)' });
          }
          else if(thisScrolled > thisOffsetMax) {
            thisBg.css({ 'transform': 'translate3d(0px, ' + thisOffsetMax + 'px, 0px)' });
          }
          else {
            thisBg.css({ 'transform': 'translate3d(0px, 0px, 0px)' });
          }
        });
      }
    }
    else {
      $all.css({ 'transform': 'initial', 'opacity': 1, 'top': 'auto' });
    }
   }

  function requestTick() {
    if(!ticking) {
      window.requestAnimationFrame(parallax);
    }
    ticking = true;
  }

  $(window).scroll(function(){
    scrollVar = $(window).scrollTop();
    requestTick();
  });
  requestTick();
}

// Init
function parallaxInit() {
  parallaxHero();
}

function responsivePixels(size, baseFontSize) {
  baseFontSize = (typeof baseFontSize !== 'undefined') ? baseFontSize : 20;
  var htmlFontSize = parseInt($('html').css('font-size').slice(0, -2));

  return size / baseFontSize * htmlFontSize;
}

/**
 * 
 * I'm sorry guys this shit's a wee bit rough right now. But it works!
 * You need to pass in an object with the following format:
 * 
 * {
 *   target: <target identifier>,
 *   activeClass: <active class>,
 *   layoutTrigger: { (Optional)
 *     property: <layout trigger property>,
 *     value: <layout trigger value>
 *   },
 *   scenes: {
 *     <scene label>: {
 *       target: <scene target>,
 *       tween: {
 *         <tween property>: <tween value>
 *       },
 *       easing: <easing value> (Optional)
 *     }
 *   }
 * }
 *
 */
function someParallax(parallaxObject) {
  $target = $(parallaxObject.target);
  var windowHeight = $(window).height();

  if ($target.length) {
    var controller = new ScrollMagic.Controller();
    var sceneGroups = {};

    // Loop through instances of jQuery object (re-evaluate)
    $target.each(function (i) {
      var $this = $(this);
      var targetHeight = $this.outerHeight();

      function getDuration() {
        var duration = windowHeight + targetHeight;

        return duration > 0 ? duration : 0;
      }

      sceneGroups[i] = {
        object: $this,
        scenes: {}
      };

      // Add active Class
      if (parallaxObject.activeClass) {
        $this.addClass(parallaxObject.activeClass);
      }

      // Loop through scenes related to target instance
      $.each(parallaxObject.scenes, function (key, scene) {
        sceneGroups[i].scenes[key] = {};
        var $sceneTarget = $this.find(scene.target);
        var sceneTween = {};

        $.each(parallaxObject.scenes[key].tween, function (tween, value) {
          if (tween === 'x' || tween === 'y') {
            sceneTween[tween] = responsivePixels(value);
          } else {
            sceneTween[tween] = value;
          }
        });

        // Create tween
        sceneGroups[i].scenes[key].tween = TweenMax.to($sceneTarget.get(0), 1, {
          css: sceneTween,
          ease: (parallaxObject.scenes[key].ease ? parallaxObject.scenes[key].ease : Linear.easeNone)
        });

        // Create scene
        sceneGroups[i].scenes[key].scene = new ScrollMagic.Scene({
          triggerElement: $this.get(0),
          triggerHook: 'onEnter',
          duration: getDuration
        }).setTween(sceneGroups[i].scenes[key].tween).addTo(controller);
      });

      // Check for triggerProperty, disable scene if value doesn't match
      if (parallaxObject.layoutTrigger) {
        if ($this.css(parallaxObject.layoutTrigger.property) !== parallaxObject.layoutTrigger.value && sceneGroups[i].scenes[Object.keys(sceneGroups[i].scenes)[0]].scene.enabled()) {
          $.each(sceneGroups[i].scenes, function (key, sceneWrapper) {
            sceneWrapper.scene.enabled(false);
          });

          $this.removeClass(parallaxObject.activeClass);
        }
      }
    });

    // @TODO: Debounce?
    $(window).on('resize', function () {
      // Loop through scene groups
      $.each(sceneGroups, function (index, sceneGroup) {
        windowHeight = $(window).height();
        targetHeight = sceneGroup.object.outerHeight();

        // Loop through scene wrappers
        $.each(sceneGroup.scenes, function (key, sceneWrapper) {
          var sceneTween = {};

          $.each(parallaxObject.scenes[key].tween, function (tween, value) {
            if (tween === 'x' || tween === 'y') {
              sceneTween[tween] = responsivePixels(value);
            } else {
              sceneTween[tween] = value;
            }
          });

          // Update tween with responsive values
          sceneWrapper.tween.progress(0);
          sceneWrapper.tween.updateTo({
            css: sceneTween
          }, true);

          sceneWrapper.scene.setTween(sceneWrapper.tween);
        });

        // Ensure triggerProperty still evaluates properly, disable if it doesn't
        if (parallaxObject.layoutTrigger) {
          if (sceneGroup.object.css(parallaxObject.layoutTrigger.property) !== parallaxObject.layoutTrigger.value && sceneGroup.scenes[Object.keys(sceneGroup.scenes)[0]].scene.enabled()) {
            $.each(sceneGroup.scenes, function (key, sceneWrapper) {
              sceneWrapper.scene.enabled(false).removeTween(true);
            });
            sceneGroup.object.removeClass(parallaxObject.activeClass);
          } else if (sceneGroup.object.css(parallaxObject.layoutTrigger.property) === parallaxObject.layoutTrigger.value && !sceneGroup.scenes[Object.keys(sceneGroup.scenes)[0]].scene.enabled()) {
            $.each(sceneGroup.scenes, function (key, sceneWrapper) {
              sceneWrapper.scene.enabled(true).setTween(sceneGroup.scenes[key].tween);
            });
            sceneGroup.object.addClass(parallaxObject.activeClass);
          }
        }
      });
    });

    return sceneGroups;
  }

  return false;
}